import moment from "moment";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { PlanApi } from "../apis/plan.api";
import showNotification from "../components/common/notification";
import { LimitedTrialPlanType, PlanType, PlanUsageType, UserPlanStatus } from "../constants/app.enums";
import { ROUTE_PATHS } from "../constants/router.constants";
import { authState } from "../states/auth";
import { planState } from "../states/plan";
import DateUtils from "../utils/date.utils";
import i18n from "../utils/i18n";
import useClient from "./useClient";

let label = ''

function useClientPlan() {
    const [plan, setPlan] = useRecoilState(planState);
    const { client, clientId, isExternalUser, isAdminViewSite } = useClient();
    const { user } = useRecoilValue(authState);
    const [isGetPremiumUrlLoading, setIsGetPremiumUrlLoading] = useState<boolean>(false)
    const [checkLimitedPlanLoading, setCheckLimitedPlanLoading] = useState<boolean>(false)

    const showUpgradeModal = () => {
        if (plan.isPlanExpired) {
            setPlan({
                ...plan,
                isShowUpgradeModal: true
            })
        }
    }

    const showLimitedTrialPlanModal = () => {
        setPlan({
            ...plan,
            isShowLimitedTrialPlanModal: true,
            limitedTrialPlanLabel: label
        })
    }

    const showLimitedStorageModal = () => {
        setPlan({
            ...plan,
            isShowLimitedStorageModal: true,
        })
    }

    const setExpiredPlan = () => {
        setPlan({
            ...plan,
            isPlanExpired: true
        })
    }

    const resetShowLimitedModal = () => {
        setPlan({
            ...plan,
            isShowLimitedTrialPlanModal: false
        })
    }

    const resetShowLimitedStorageModal = () => {
        setPlan({
            ...plan,
            isShowLimitedStorageModal: false
        })
    }

    const resetShowUpgradeModal = () => {
        setPlan({
            ...plan,
            isShowUpgradeModal: false
        })
    }


    const getUsage = async () => {
        const { data } = await PlanApi.getClientUsagePlan(clientId)
        return data;
    }

    const checkLimitedPlan = async (type: PlanUsageType) => {
        const userPlan = getUserPlan(type);

        if (userPlan) {
            setCheckLimitedPlanLoading(true)
            try {
                const { data } = await PlanApi.checkClientUsagePlan(type, clientId);

                switch (type) {
                    case PlanUsageType.Company:
                        label = LimitedTrialPlanType.Companies;
                        break;
                    case PlanUsageType.TeamMember:
                        label = LimitedTrialPlanType.TeamMembers;
                        break;
                    case PlanUsageType.PostPerCom:
                        label = LimitedTrialPlanType.PostsPerCompany;
                        break;
                    case PlanUsageType.ReportPerCom:
                        label = LimitedTrialPlanType.Reports;
                        break;
                    default:
                        break;
                }

                return data.isHitLimit;
            } finally {
                setCheckLimitedPlanLoading(false)
            }
        }

        return false;
    };


    // If Company get plan of current user else get plan of selected client
    const getUserPlan = (planUsage: PlanUsageType) => planUsage === PlanUsageType.Company ? user?.clients.find(x => x.email === user.email)?.userPlan : client?.userPlan;

    const checkUserPlan = async (cb: () => void, planUsage: PlanUsageType) => {

        const userPlan = getUserPlan(planUsage);

        if (!userPlan) return;

        const isPlanExpired = moment(DateUtils.addUTCCharater(userPlan?.expiredTime)) < moment();

        //If plan is expired and plan isn't Premium 
        // => Show upgrade modal
        if ((plan.isPlanExpired || isPlanExpired) && userPlan?.planType !== PlanType.Premium) {
            showUpgradeModal();
        } else {
            const isHitLimit = await checkLimitedPlan(planUsage);
            if (isHitLimit) {
                if (planUsage === PlanUsageType.Storage) {
                    showLimitedStorageModal();
                }
                else {
                    showLimitedTrialPlanModal();
                }
            } else {
                //Pass all case, do cb()
                cb();
            }
        }
    }

    const checkPremiumPlan = (cb: () => void, planUsage: PlanUsageType) => {
        const userPlan = getUserPlan(planUsage);

        if (!userPlan) return;
        const isPlanExpired = moment(DateUtils.addUTCCharater(userPlan?.expiredTime)) < moment();

        if (userPlan?.planType === PlanType.Premium && isPlanExpired) {
            if (userPlan?.isPaymentError) {
                showNotification("error",
                    i18n.t("usageAndBilling.cannotProceedErrorMsg", {
                        url: ROUTE_PATHS.Stripe
                    }))
            } else {
                showNotification("error",
                    i18n.t("usageAndBilling.canceledErrorMsg", {
                        url: ROUTE_PATHS.Stripe
                    }))
            }

        } else {
            cb();
        }
    }

    const checkEndedTrialPlan = (cb: () => void) => {
        //If plan is expired and plan isn't Premium 
        // => Show upgrade modal
        if (plan.isPlanExpired && client?.userPlan?.planType !== PlanType.Premium) {
            showUpgradeModal()
        }
        else {
            cb()
        }
    }

    const getUpgradePremiumUrl = async () => {

        if (isExternalUser() || isAdminViewSite) { return; }
        setIsGetPremiumUrlLoading(true)
        try {
            const { data } = await PlanApi.getUpgradePremiumUrl()
            window.location.href = data.url;
        } finally {
            setIsGetPremiumUrlLoading(false)
        }
    }

    const setCanceledPlan = () => {
        setPlan({
            ...plan,
            planStatus: UserPlanStatus.Canceled
        })
    }

    useEffect(() => {
        if (client) {
            if (client?.userPlan) {
                setPlan({
                    ...plan,
                    planType: client?.userPlan?.planType,
                    planStatus: client?.userPlan?.status
                })
            }
        }
    }, [client])

    return {
        isPlanExpired: plan.isPlanExpired,
        isShowUpgradeModal: plan.isShowUpgradeModal,
        isShowLimitedTrialPlanModal: plan.isShowLimitedTrialPlanModal,
        isShowLimitedStorageModal: plan.isShowLimitedStorageModal,
        limitedTrialPlanLabel: plan.limitedTrialPlanLabel,
        isFreeTrial: client?.userPlan?.planType === PlanType.Trial,
        isFreePlan: client?.userPlan?.planType === PlanType.Free,
        isPremiumPlan: client?.userPlan?.planType === PlanType.Premium,
        userPlan: client?.userPlan,
        planStatus: plan.planStatus,
        showUpgradeModal,
        setExpiredPlan,
        resetShowUpgradeModal,
        resetShowLimitedModal,
        resetShowLimitedStorageModal,
        checkUserPlan,
        checkEndedTrialPlan,
        getUsage,
        getUpgradePremiumUrl,
        isGetPremiumUrlLoading,
        checkLimitedPlanLoading,
        checkPremiumPlan,
        setCanceledPlan
    };
}

export default useClientPlan;
