import { Button, Checkbox, Modal } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SocialApi } from "../../../../../../apis/social.api";
import { SocialType } from "../../../../../../constants/app.enums";
import useClient from "../../../../../../hooks/useClient";
import useService from "../../../../../../hooks/useService";
import { ContentModel, SocialContentModel } from "../../../../../../models/content.model";
import showNotification from "../../../../../common/notification";
import "./index.scss";

const CheckboxGroup = Checkbox.Group;
interface DeleteContentModalProps {
  open: boolean;
  close: () => void;
  contentInfo: ContentModel;
  isCalendarPage?: boolean;
}
const ContentLabelMapping = (platform: SocialType, isReels?: boolean) => {
  const type = isReels ? "Reel" : "Post";
  switch (platform) {
    case SocialType.Facebook:
      return  "Facebook " + type;
    case SocialType.Instagram:
      return "Instagram " + type;
    case SocialType.Tiktok:
      return "Tiktok Video";
    default:
      return "";
  }
};

const DeleteContentModal = (props: DeleteContentModalProps) => {
  const { open, close, contentInfo, isCalendarPage } = props;
  const { t } = useTranslation();
  const { SocialService } = useService();
  const { clientId } = useClient();

  const [loading, setLoading] = useState(false);
  const { platformContents, id } = contentInfo;
  const [listContentDeleteChecked, setListContentDeleteChecked] = useState<
    SocialContentModel[]
  >(platformContents);

  const ListContent = platformContents.map(content => {
    return {
      label: ContentLabelMapping(content.platform, content.isReels),
      value: content.id ?? 0,
    }
  })

  const deleteContent = async () => {
    setLoading(true);
    try {
      await SocialApi.deleteContent({
        contentId: id,
        platformIds: listContentDeleteChecked.map(x => x.id ?? 0),
      });
      if (listContentDeleteChecked.length === ListContent.length)
        showNotification(
          "success",
          "Your content has been successfully deleted!"
        );
      else {
        const listContentText = listContentDeleteChecked
          .map((content) => ContentLabelMapping(content.platform, content.isReels))
          .join(",");
        showNotification(
          "delete",
          `Your content for ${listContentText} has been successfully deleted!`
        );
      }
      isCalendarPage
        ? SocialService.getListContentCalendar({ clientId })
        : SocialService.getListContent({ clientId });
    } catch (error) { }
    setLoading(false);
    close();
  };

  useEffect(() => {
    open &&
      setListContentDeleteChecked(platformContents);
  }, [open]);
  return (
    <Modal
      title={null}
      open={open}
      onCancel={close}
      closable={false}
      closeIcon={null}
      maskClosable
      footer={null}
      className="custom-create-modal delete-content-modal"
    >
      <div className="delete-content-modal-inner">
        <p className="title">{t("social.content.deleteContentTitle")}</p>
        <p className="description">
          {t("social.content.deleteContentDescription")}
        </p>

        <div className="delete-content-modal-inner__list-content">
          <CheckboxGroup
            options={ListContent}
            value={listContentDeleteChecked.map(x => x.id ?? 0)}
            onChange={(values: any) => {
              setListContentDeleteChecked(platformContents.filter(p => values.includes(p.id)));
            }}
          />
        </div>

        <div className="group-btn">
          <Button type="text" onClick={close}>
            {t("common.cancel")}
          </Button>
          <Button
            type="primary"
            danger
            onClick={deleteContent}
            loading={loading}
            disabled={listContentDeleteChecked.length === 0}
          >
            {t("common.delete")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
export default DeleteContentModal;
