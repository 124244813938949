/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Dropdown, Popover } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { AuthApi } from "../../../apis/auth.api";
import WhiteLogoMobile from "../../../assets/images/white-logo-mobile.svg";
import WhiteLogo from "../../../assets/images/white-logo.svg";
import { LOCAL_COOKIE_KEY } from "../../../constants/app-constants";
import { ROUTE_PATHS } from "../../../constants/router.constants";
import useAdminSite from "../../../hooks/useAdminSite";
import useClient from "../../../hooks/useClient";
import useDashboardPath from "../../../hooks/useDashboardPath";
import { GeneralSettingItems } from "../../../pages/general-settings";
import {
  authIsSupperAdminSelector,
  authRoleSelector,
  authState
} from "../../../states/auth";
import { navbarOpenState } from "../../../states/common";
import { notificationState } from "../../../states/notification";
import { listProfileState } from "../../../states/social";
import LocalUtils from "../../../utils/local.utils";
import AppAvatar from "../../common/app-avatar/app-avatar";
import ListNotification from "../../common/list-notification";
import SVGIcons from "../../icons/svgs";
import AdminMyProfile from "../../views/admin-my-profile";
import "./index.scss";
import useClientPlan from "../../../hooks/useClientPlan";

function MainHeader() {
  const [auth, setAuth] = useRecoilState(authState);
  const { t } = useTranslation();
  const role = useRecoilValue(authRoleSelector);
  const isSupperAdmin = useRecoilValue(authIsSupperAdminSelector);
  const navigate = useNavigate();
  const isAdminSite = useAdminSite();
  const dashboardPath = useDashboardPath();
  const [openMyProfile, setOpenMyprofile] = useState(false);
  const { getRouter, isAdminViewSite } = useClient();
  const [menuOpen, setMenuOpen] = useRecoilState(navbarOpenState);
  const notification = useRecoilValue(notificationState);
  const resetProfileSocial = useResetRecoilState(listProfileState);
  const {isPremiumPlan} = useClientPlan()

  useEffect(() => {
    if (!auth.isLogined) {
      signOut();
    }
  }, [auth]);

  async function signOut() {
    let idToken = LocalUtils.getCookie(LOCAL_COOKIE_KEY.ID_TOKEN);
    if (idToken) {
      await AuthApi.signOut();
    }

    LocalUtils.clear();
    if (isAdminSite) {
      navigate(ROUTE_PATHS.AdminSignIn);
    } else {
      navigate(ROUTE_PATHS.SignIn);
    }
  }

  function editMyprofile() {
    setOpenMyprofile(true);
  }

  function gotoGeneralSettings() {
    const link = getRouter(ROUTE_PATHS.GeneralSettings);
    navigate(`${link}?tab=${GeneralSettingItems.account}`);
  }

  const getMenuItems = useCallback(() => {
    let items = [];
    if (isAdminSite && !isAdminViewSite) {
      items = [
        {
          key: "1",
          label: (
            <a
              className="main-header__userInfo-title"
              onClick={editMyprofile}
              rel="noopener noreferrer"
            >
              {t("common.userProfile")}
            </a>
          ),
          icon: (
            <span className="main-header__userInfo-icon">
              <SVGIcons.UserCircleIcon />
            </span>
          ),
        },
      ];

      if (isSupperAdmin) {
        items.push({
          key: "2",
          label: (
            <Link
              className="main-header__userInfo-title"
              to={ROUTE_PATHS.RolePermission}
            >
              {t("common.rolesPermissions")}
            </Link>
          ),
          icon: (
            <span className="main-header__userInfo-icon">
              <SVGIcons.FilterIcon />
            </span>
          ),
        });
      }
    } else {
      items.push({
        key: "3",
        label: (
          <a
            className="main-header__userInfo-title"
            onClick={gotoGeneralSettings}
          >
            {t("common.generalSettings")}
          </a>
        ),
        icon: (
          <span className="main-header__userInfo-icon">
            <SVGIcons.SettingIcon />
          </span>
        ),
      });
    }
    items.push({
      key: "8",
      label: (
        <a
          className="main-header__userInfo-title"
          onClick={() =>
            navigate(`${getRouter(ROUTE_PATHS.UsageAndBilling)}`)
          }
        >
          {t("dashboard.usageAndBilling")}
        </a>
      ),
      icon: (
        <span className="main-header__userInfo-icon chart-icon">
          <SVGIcons.ChartIcon />
        </span>
      ),
    })
    if (!isAdminSite) {
      items.push({
        key: "7",
        label: (
          <a
            className="main-header__userInfo-title"
            onClick={() =>
              navigate(`${ROUTE_PATHS.SelectCompany}?isAddCompany=true`)
            }
          >
            {t("dashboard.addCompany")}
          </a>
        ),
        icon: (
          <span className="main-header__userInfo-icon">
            <SVGIcons.CirclePlusIcon />
          </span>
        ),
      });
    }
    items = [
      {
        key: "6",
        label: (
          <div className="main-header__userInfo">
            <div>
              <AppAvatar
                size={46}
                shape="square"
                src={auth.user?.profilePictureUrl}
              />
            </div>
            <div>
              <div className="main-header__userInfo-name">
                <span>{`${auth.user?.firstName} ${auth.user?.lastName}`}</span>
              </div>
              <div className="main-header__userInfo-email">
                <span>{auth.user?.email}</span>
              </div>
            </div>
          </div>
        ),
      },
     
      ...items,
   
      {
        type: "divider",
        key: "5",
      },
      {
        key: "4",
        label: (
          <a
            className="main-header__userInfo-title"
            rel="noopener noreferrer"
            onClick={logout}
          >
            {t("common.logOut")}
          </a>
        ),
        icon: (
          <span className="main-header__userInfo-icon">
            <SVGIcons.LogoutIcon />
          </span>
        ),
      },
    ];

    return items;
  }, [isSupperAdmin]);

  function logout() {
    setAuth({ isLogined: false, user: undefined });
    resetProfileSocial();
  }

  function toggleModel() {
    setMenuOpen(!menuOpen);
  }

  return (
    <>
      <div className="main-header header-desktop">
        <div className="main-header__logo">
          <Link to={dashboardPath}>
            <img src={WhiteLogo} alt="logo" />
          </Link>
          {
            !isAdminSite && isPremiumPlan && <div className="main-header__paid-plan" >
              <div>
                <SVGIcons.StarFilledIcon />
              </div>
              <div>
                {t("clientSites.premium")}
              </div>
            </div>
          }
          {isAdminSite && <span>admin</span>}
        </div>

        <div className="main-header__rightControl">
          {
            isAdminSite &&  <a
            href="https://forms.clickup.com/14262676/f/dk8cm-81785/4RN64LKQPW0Y7GAN36"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button type="text" shape="circle">
              <SVGIcons.LeftMessageIcon />
            </Button>
          </a>
          }
         
          <a
            href={
              isAdminSite
                ? "https://admindocs.rocketlevel.com"
                : "https://docs.rocketlevel.com"
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button type="text" shape="circle">
              <SVGIcons.BookIcon />
            </Button>
          </a>
          <Popover
            content={<ListNotification />}
            placement="bottomRight"
            trigger={["click"]}
            overlayClassName="popover-notification"
          >
            <Button type="text" shape="circle" className="btn-notification">
              <SVGIcons.BellIcon />
              {notification.unread > 0 && (
                <span className="new-notification"></span>
              )}
            </Button>
          </Popover>

          <Dropdown
            menu={{ items: getMenuItems() }}
            arrow
            placement="bottomRight"
            overlayClassName="header-menu-dropdown"
          >
            <div className="main-header__user">
              <AppAvatar
                size={32}
                shape="square"
                src={auth.user?.profilePictureUrl}
              />

              <div className="main-header__user__name">
                <span>{`${auth.user?.firstName} ${auth.user?.lastName}`}</span>
                <span>{isAdminSite ? role : ""}</span>
              </div>
            </div>
          </Dropdown>
        </div>
      </div>

      <div className="main-header header-mobile">
        <div className="main-header__left">
          <Button
            shape="circle"
            type="link"
            className={`navbar-toggler${menuOpen ? " open" : ""}`}
            onClick={toggleModel}
          >
            {/* <SVGIcons.MenuIcon /> */}
            <span className="navbar-toggler-icon"></span>
          </Button>
        </div>
        <div className="main-header__logo">
          <Link to={dashboardPath}>
            <img src={WhiteLogoMobile} alt="logo" />
          </Link>
          {isAdminSite && <span>admin</span>}
        </div>

        <div className="main-header__rightControl">
          <div className="main-header-mobile">
            <Dropdown
              menu={{ items: getMenuItems() }}
              arrow
              placement="bottomRight"
              overlayClassName="header-menu-dropdown mobile"
            >
              <div className="main-header__user">
                <AppAvatar
                  size={32}
                  shape="square"
                  src={auth.user?.profilePictureUrl}
                />
              </div>
            </Dropdown>
          </div>
        </div>
      </div>

      <AdminMyProfile
        open={openMyProfile}
        close={() => setOpenMyprofile(false)}
      />
    </>
  );
}

export default MainHeader;
