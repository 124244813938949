import { Carousel, Tooltip } from "antd";
import { useRef, useState } from "react";
import { useHorizontalScroll } from "../../../../../../hooks/useScrollHorizontal";
import MediaUtils from "../../../../../../utils/media.utils";
import AppLightbox from "../../../../../common/light-box";
import SVGIcons from "../../../../../icons/svgs";
import MediaSocial from "../media-social";
import "./index.scss";
import { ContentMediaModel } from "../../../../../../models/content.model";

interface ListMediaProps {
  listMedia: ContentMediaModel[];
  onRemoveMedia?: (id: number) => void;
  onReplaceMedia?: (id: number) => void;
  isEdit?: boolean;
  hasLightbox?: boolean;
}

const ListMediaCreateContent = (props: ListMediaProps) => {
  const { listMedia, onRemoveMedia, onReplaceMedia, isEdit } = props;
  const scrollRef: any = useHorizontalScroll();
  const [lightBoxOpen, setLightBoxOpen] = useState(false);
  const [lightBoxStartIndex, setLightBoxStartIndex] = useState(0);

  const openLightBox = (index: number) => {
    setLightBoxOpen(true);
    setLightBoxStartIndex(index);
  }

  return listMedia.length ? (
    <div
      className={`list-media-create-content${listMedia.length === 1 ? " media-no-padding" : ""
        }`}
      ref={scrollRef}
    >
      <AppLightbox listMedia={listMedia.map(x => x.url)} index={lightBoxStartIndex} open={lightBoxOpen} close={() => setLightBoxOpen(false)} />
      {listMedia.map((media: ContentMediaModel, index: number) => {
        return (
          <div className="media-item" key={index}>
            <MediaSocial url={media.url} openLightBox={() => openLightBox(index)} />

            {isEdit && (
              <div className="overlay">
                <Tooltip title="Replace media">
                  <div
                    className="replace"
                    onClick={() => onReplaceMedia && onReplaceMedia(index)}
                  >
                    <SVGIcons.EditIcon />
                  </div>
                </Tooltip>
                <Tooltip title="Remove media">
                  <div
                    className="remove"
                    onClick={() => onRemoveMedia && onRemoveMedia(index)}
                  >
                    <SVGIcons.CloseIcon />
                  </div>
                </Tooltip>
              </div>
            )}
          </div>
        );
      })}{" "}
    </div>
  ) : (
    <></>
  );
};

const ListMediaContentCard = (props: ListMediaProps) => {
  const { listMedia, hasLightbox } = props;
  const [lightBoxOpen, setLightBoxOpen] = useState(false);
  const [lightBoxStartIndex, setLightBoxStartIndex] = useState(0);

  const openLightBox = (index: number) => {
    setLightBoxOpen(true);
    setLightBoxStartIndex(index);
  }

  const carouselRef: any = useRef(null);

  const preSlide = () => {
    if (carouselRef.current) carouselRef.current.prev();
  };
  const nextSlide = () => {
    if (carouselRef.current) carouselRef.current.next();
  };
  return (
    <div className="list-media-content-card">
      {hasLightbox && <AppLightbox listMedia={listMedia.map(x => x.url)} index={lightBoxStartIndex} open={lightBoxOpen} close={() => setLightBoxOpen(false)} />}
      <Carousel dots={false} ref={carouselRef}>
        {listMedia.map((media: ContentMediaModel, index: number) => {
          const extensionFile = MediaUtils.getExtension(media.url);
          return (
            <div className="media-item" key={media.url} onClick={() => openLightBox(index)} >
              {!["mp4", "mov"].includes(extensionFile) ? (
                <img className="image-list" src={media.url} alt="" />
              ) : (
                <video height="260" className={hasLightbox ? 'video-with-light-box' : ''} controls preload="metadata" key={media.url}>
                  <source src={`${media.url}#t=0.1`} type="video/mp4" />
                </video>
              )}
              {
                listMedia.length > 1 &&
                <div className="number-image-overlay">
                  {`${index + 1}/${listMedia.length}`}
                </div>
              }
            </div>
          );
        })}{" "}
      </Carousel>
      {listMedia.length > 1 && (
        <>
          <div className="btn-slide pre-slide" onClick={preSlide}>
            <SVGIcons.ChevronLeft />
          </div>
          <div className="btn-slide next-slide" onClick={nextSlide}>
            <SVGIcons.ChevronRight />
          </div>
        </>
      )}
    </div>
  );
};

export default { ListMediaCreateContent, ListMediaContentCard };
