import { Button, Dropdown, Modal, Pagination, Select, Switch, Table, Tabs, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { AuthApi } from "../../../../apis/auth.api";
import { ReportApi } from "../../../../apis/report.api";
import {
  DATE_TIME_FORMAT
} from "../../../../constants/app-constants";
import { PlanUsageType, ReportType } from "../../../../constants/app.enums";
import { ROUTE_PATHS } from "../../../../constants/router.constants";
import useClient from "../../../../hooks/useClient";
import useClientPlan from "../../../../hooks/useClientPlan";
import { EmailReportSettingsModel } from "../../../../models/client-settings.model";
import { GenerateReportRequestModel, GetReportsRequest } from "../../../../models/report.model";
import DateUtils from "../../../../utils/date.utils";
import showNotification from "../../../common/notification";
import SVGIcons from "../../../icons/svgs";
import EditReportSettings from "./edit-form";
import GenerateReportForm from "./generate-form";
import "./index.scss";
import SendEmailForm from "./send-email-form";

const { TabPane } = Tabs;

const PAGE_SIZE = 10


function GeneralSettingReports() {
  const { t } = useTranslation();
  const [openEditReport, setOpenEditReport] = useState(false);
  const [currentReport, setCurrentReport] = useState<GenerateReportRequestModel>();
  const [loading, setLoading] = useState(false);
  const [openEditSetting, setOpenEditSetting] = useState(false);
  const [settings, setSettings] = useState<EmailReportSettingsModel[]>([]);
  const [reports, setReports] = useState<GenerateReportRequestModel[]>([]);
  const [reportType, setReportType] = useState(ReportType.General);
  const [pageIndex, setPageIndex] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [openGenerateModal, setOpenGenerateModal] = useState<boolean>(false);
  const [openEmailReport, setOpenEmailReport] = useState<boolean>(false);
  const [switchLoading, setSwitchLoading] = useState<boolean>(false);
  const { checkUserPlan, checkLimitedPlanLoading, checkPremiumPlan } = useClientPlan()
  const [reportAction, setReportAction] = useState<'switch' | 'button'>()
  const { clientId, client } = useClient();

  const changeTab = (tabKey: any) => {
    setReportType(tabKey);
    initListReport({
      reportType: tabKey,
      pageIndex: 1
    })
  };
  const itemRender = (page: any, type: any, originalElement: any) => {
    if (type === "prev") {
      return (
        <>
          <SVGIcons.ChevronLeft /> {t("clientSites.prev")}
        </>
      );
    }
    if (type === "next") {
      return (
        <>
          {t("clientSites.next")} <SVGIcons.ChevronRight />
        </>
      );
    }
    return originalElement;
  };


  async function getClientSettings() {
    if (!clientId) {
      return;
    }
    try {
      const { data } = await AuthApi.clientSettings(clientId);
      const settings = data.map((item) => {
        return {
          ...item,
          reportType: item.reportName === "Dashboard" ? ReportType.General : ReportType.Social
        }
      })
      setSettings(settings);
    } finally {
    }
  }


  async function onOpenEditSetting(reportType: ReportType) {
    if (getSettingByTab(reportType)?.isEnabled) {
      setOpenEditSetting(true)
    }
  }

  const confirmDelete = (report: GenerateReportRequestModel) => {
    Modal.confirm({
      title: t("generalSettings.confirmDeleteReport"),
      content: (
        <span
          dangerouslySetInnerHTML={{
            __html: t("generalSettings.confirmDeleteReportDesc", {
              title: report.title,
              range: `${moment(report.startDate).format(DATE_TIME_FORMAT.viewFormat)} - ${moment(report.endDate).format(DATE_TIME_FORMAT.viewFormat)}`,
            }),
          }}
        ></span>
      ),
      okText: t("generalSettings.confirmDeleteReportText"),
      cancelText: t("common.cancel"),
      cancelButtonProps: { type: "text" },
      icon: "",
      className: "confirm-modal confirm-delete-modal",
      centered: true,
      onOk: async () => {
        setLoading(true);
        try {
          if (report.id) {
            await ReportApi.deleteReport(report?.id);
            showNotification(
              "delete",
              t("generalSettings.deleteReportSuccess", {
                title: report.title,
              })
            );
            if (totalRecords - 1 === PAGE_SIZE) {
              await initListReport({
                pageIndex: 1,
                reportType
              })
            } else {
              await initListReport({
                pageIndex,
                reportType
              })
            }

          }
        } finally {
          setLoading(false);
        }

      },
    });
  };

  const getMenuItems = (report: GenerateReportRequestModel) => {
    let menus = [
      {
        label: <span>{t("generalSettings.editReport")}</span>,
        key: "1",
        onClick: () => editReport(report),
      },
      {
        label: <span>{t("generalSettings.emailReport")}</span>,
        key: "2",
        onClick: () => sendEmailReport(report),
      },
      {
        type: "divider",
        key: "3",
      },
      {
        label: t("generalSettings.deleteReport"),
        key: "4",
        danger: true,
        onClick: () => confirmDelete(report),
      },
    ];

    return menus;
  };


  function editReport(record: GenerateReportRequestModel) {
    setCurrentReport(record);
    setOpenEditReport(true);
  }

  async function submitEdit(record: EmailReportSettingsModel) {
    await AuthApi.updateClientSetting(record);
    setOpenEditReport(false);
    getClientSettings();
  }

  async function enableSetting(reportType: ReportType, isEnable: boolean) {
    setReportAction("switch")
    await checkUserPlan(async () => {
      if (settings.length > 0) {
        const clientSettingId = settings.find(x => x.reportType === reportType)?.id
        if (clientSettingId) {
          setSwitchLoading(true)
          try {
            await AuthApi.enableSetting({ clientSettingId, isEnabled: isEnable });
            await getClientSettings()
          } finally {
            setSwitchLoading(false)
          }
        }
      }
    }, PlanUsageType.ReportPerCom);
  }

  function getSettingByTab(reportType: ReportType) {
    if (settings.length > 0) {
      const currentSetting = settings.find(x => x.reportType === reportType)
      return currentSetting
    }
  }

  const columns: ColumnsType<GenerateReportRequestModel> = [
    {
      title: t("generalSettings.reportTitle"),
      dataIndex: "title",
      key: "title",
      render: (text, record) => {
        return <Link to={`${ROUTE_PATHS.Reports}/${record.id}`} target="_blank" className="report-title">
          {text}
        </Link>
      }
    },
    {
      title: t("generalSettings.dateRange"),
      dataIndex: "dateRange",
      key: "dateRange",
      render: (_, record: GenerateReportRequestModel) => {
        return <span>
          {moment(record.startDate).format(DATE_TIME_FORMAT.viewFormat)} - {moment(record.endDate).format(DATE_TIME_FORMAT.viewFormat)}
        </span>
      }
    },
    {
      title: t("generalSettings.generatedAt"),
      dataIndex: "generateDate",
      key: "generateDate",
      render: (text, record) => {
        return <span>
          {
            text &&
            DateUtils.convertUTCTimeToTimezone(
              client?.timeZoneInfo?.id,
              text
            ).format(DATE_TIME_FORMAT.dateTimeSecondViewFormat)
          }
        </span>
      }
    },

    {
      title: t("generalSettings.recentlySentAt"),
      dataIndex: "recentlySentAt",
      key: "recentlySentAt",
      render: (text, record) => {
        return <span>
          {
            text ?
              DateUtils.convertUTCTimeToTimezone(
                client?.timeZoneInfo?.id,
                text
              ).format(DATE_TIME_FORMAT.dateTimeSecondViewFormat)
              : "-"
          }
        </span>
      }
    },

    {
      title: t("generalSettings.sentTo"),
      dataIndex: "sentTo",
      key: "sentTo",
      render: (text, record) => {
        const emails: string[] = text ? text.split(', ') : []
        return text ? emails.map((item, index) => (
          <div key={index} >{item}</div>
        )) : "-"
      }
    },


    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Dropdown
          menu={{ items: getMenuItems(record) }}
          trigger={["click"]}
          placement="bottomRight"
        >
          <Button className="dropdown-action-button">
            {t("clientSites.action")}
            <SVGIcons.ChevronDown />
          </Button>
        </Dropdown>
      ),
    },
  ];

  const renderTable = () => {
    return <>
      <Table
        columns={columns}
        dataSource={reports}
        rowKey="id"
        className="main-table"
        pagination={false}
        loading={loading}
        locale={{ emptyText: <div className="noData" >{t("generalSettings.noDataAvailable")}</div> }}
      />
      {
        totalRecords > PAGE_SIZE &&
        <Pagination
          current={pageIndex}
          total={totalRecords}
          pageSize={PAGE_SIZE}
          className="reports-pagination"
          itemRender={itemRender}
          onChange={onChangePagination}
        />
      }
    </>
  }

  const initListReport = async (request?: GetReportsRequest) => {
    if (!clientId) {
      showNotification("error", "Client not found");
      return;
    }
    setLoading(true);
    try {

      const { data } = await ReportApi.getReports({
        clientId,
        pageSize: PAGE_SIZE,
        ...request,
      });
      setReports(data.records)
      setTotalRecords(data.totalRecords);
      setPageIndex(request?.pageIndex || 1);
    } catch (error) {
      setLoading(false);
    }
    setLoading(false);
  };
  function sendEmailReport(record: GenerateReportRequestModel) {
    setCurrentReport(record);
    setOpenEmailReport(true)
  }

  const onChangePagination = async (page: number) => {
    if (clientId) {
      await initListReport({
        clientId,
        reportType,
        pageIndex: page
      });
    }
  };

  useEffect(() => {
    getClientSettings();

    initListReport({
      pageIndex: 1,
      reportType
    })
  }, [clientId]);

  return (
    <div className="general-settings-reports">
      {
        settings.length > 1 &&
        <div className="general-settings-reports__tabs" >
          {
            settings.map((item) => (
              <Button key={item.id} type={reportType === item.reportType ? "primary" : "link"}
                onClick={() => changeTab(item.reportType)}> {item.reportName} {t("generalSettings.report")}
              </Button>
            ))
          }
        </div>
      }
      <Tabs className="general-settings-reports__tabsContent" activeKey={reportType.toString()} onChange={changeTab}>
        <TabPane key="1">
          <div className="tab-heading" >
            <div>
              <span className="title" >{t("generalSettings.reportsFrom")} </span>
              <a target="_blank" href={ROUTE_PATHS.Home}>
                {t("generalSettings.dashboard")}
              </a>
            </div>
            <div className="switch">
              <Switch loading={switchLoading || (checkLimitedPlanLoading && reportAction === "switch")} checked={getSettingByTab(ReportType.General)?.isEnabled}
                onChange={(e) => enableSetting(ReportType.General, e)} size="small" />
              <span onClick={() => onOpenEditSetting(ReportType.General)} className={`auto-reporting ${getSettingByTab(ReportType.General)?.isEnabled && "clickable"}`} >{t("generalSettings.autoReporting")}
              </span>
              <Tooltip overlayClassName="custom-tooltip" title={
                <span>
                  {t("generalSettings.autoReportingTooltip")}</span>}>
                <span className="circle-question" >?
                </span>
              </Tooltip>

            </div>
          </div>
          {renderTable()}
        </TabPane>
        <TabPane key="2">
          <div className="tab-heading">
            <div>
              <span className="title">{t("generalSettings.reportsFrom")} </span>
              <a target="_blank" href={ROUTE_PATHS.Social.OverViewTab}>
                {t("generalSettings.socialDashboard")}
              </a>
            </div>
            <div className="switch" >
              <Switch loading={switchLoading || (checkLimitedPlanLoading && reportAction === "switch")} checked={getSettingByTab(ReportType.Social)?.isEnabled}
                onChange={(e) => enableSetting(ReportType.Social, e)} size="small" />
              <span onClick={() => onOpenEditSetting(ReportType.Social)} className={`auto-reporting ${getSettingByTab(ReportType.Social)?.isEnabled && "clickable"}`} >{t("generalSettings.autoReporting")}</span>
              <Tooltip overlayClassName="custom-tooltip" title={
                <span>
                  {t("generalSettings.autoReportingTooltip")}</span>}>
                <span className="circle-question" >?
                </span>
              </Tooltip>
            </div>
          </div>
          {renderTable()}
        </TabPane>
      </Tabs>
      <div className="generate-report" >
        <Button
          loading={checkLimitedPlanLoading && reportAction === "button"}
          onClick={() => {
            setReportAction("button")
            checkPremiumPlan(async () => {
              await checkUserPlan(() => {
                setOpenGenerateModal(true)
              }, PlanUsageType.ReportPerCom);
            }, PlanUsageType.ReportPerCom)
          }}
          type="primary"
          className="secondary-btn btn-connect"
        >
          {t("generalSettings.generateReport")}
        </Button>
      </div>
      <EditReportSettings
        open={openEditSetting}
        item={getSettingByTab(reportType)}
        close={() => setOpenEditSetting(false)}
        submit={submitEdit}
      />

      <GenerateReportForm
        modalTitle={reportType === ReportType.General ? t("generalSettings.generalGenerate") : t("generalSettings.socialGenerate")}
        reportType={reportType}
        open={openGenerateModal}
        close={() => setOpenGenerateModal(false)}
        initListReport={initListReport}
        pageIndex={pageIndex}
      />

      <GenerateReportForm
        modalTitle={t("generalSettings.editReport")}
        reportType={reportType}
        open={openEditReport}
        close={() => setOpenEditReport(false)}
        initListReport={initListReport}
        isEdit
        currentReport={currentReport}
        pageIndex={pageIndex}
      />

      <SendEmailForm
        open={openEmailReport}
        initListReport={() => initListReport({
          clientId,
          reportType,
          pageIndex: pageIndex
        })}
        close={() => setOpenEmailReport(false)}
        currentReport={currentReport}
      />
    </div>
  );
}

export default GeneralSettingReports;

