import "./index.scss";

import { Button } from "antd";
import { useTranslation } from "react-i18next";
import logo from "../../../assets/images/logo.svg";
import SVGIcons from "../../../components/icons/svgs";
import { SUPPORT_EMAIL } from "../../../constants/app-constants";

function MaintenancePage() {
  const { t } = useTranslation();

  return (
    <div className="not-found-page">
      <div className="not-found-page__header">
        <a href={"https://rocketlevel.com/"}>
          <img src={logo} />
        </a>
      </div>

      <div className="not-found-page__body">
        <h1 dangerouslySetInnerHTML={{ __html: t("maintenance.title") }}></h1>
        <p
          dangerouslySetInnerHTML={{ __html: t("maintenance.description") }}
        ></p>
        <a href="https://rocketlevel.com/">
          <Button
            icon={<SVGIcons.WordIcon />}
            type="primary"
          >
            {t("maintenance.backToHome")}
          </Button>
        </a>

        <a
          href={`mailto:${SUPPORT_EMAIL}`}
          className="text-success">
          {t("maintenance.contactSupport")}
        </a>
      </div>

      <div className="not-found-page__foolter">
        <small>{t("common.copyRight")}</small>
      </div>
    </div>
  );
}

export default MaintenancePage;
