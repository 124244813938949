import { useTranslation } from "react-i18next";
import AddConnection from "./add-connection";
import "./index.scss";
import IntegrationCollapse from "../../../common/integration-collapse";
import { useRecoilValue } from "recoil";
import { clientSitesState } from "../../../../states/clientSites";
import useFetchConnection from "../../../../hooks/useFetchConnection";
import { useEffect } from "react";

function IntergrationForm() {
  const { fetchConnection } = useFetchConnection();
  const { t } = useTranslation();
  const { clientSelected } = useRecoilValue(clientSitesState);

  useEffect(() => {
    if (clientSelected) {
      fetchConnection();
    }
  }, [clientSelected])

  return (
    <div className="integration-form">
      <p>{t("clientSites.integrationDes")}</p>

      {clientSelected?.id && <IntegrationCollapse clientId={clientSelected?.id} />}

      <AddConnection />
    </div>
  );
}

export default IntergrationForm;
