import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { NotificationApi } from "../apis/notification.api";
import { SocialApi } from "../apis/social.api";
import { PostStatusType, SocialType, SubmitType } from "../constants/app.enums";
import {
  CreateContentRequest,
  GetListContentRequestModel,
} from "../models/content.model";
import { NotificationModel } from "../models/notification.model";
import { notificationState } from "../states/notification";
import {
  contentValueState,
  listContentCalendarState,
  listContentState,
  listPostTypeState,
  listTagState
} from "../states/social";
import DateUtils from "../utils/date.utils";
import useClient from "./useClient";

const useService = () => {
  const { client } = useClient();
  const [notification, setNotification] = useRecoilState(notificationState);
  const contentValue = useRecoilValue(contentValueState);
  const [contentState, setContentState] = useRecoilState(listContentState);
  const [contentCalendarState, setContentCalendarState] = useRecoilState(
    listContentCalendarState
  );
  const setListTag = useSetRecoilState(listTagState);
  const setListPostType = useSetRecoilState(listPostTypeState);
  // notification
  const getListNotifications = async () => {
    try {
      const { data } = await NotificationApi.getNotifications();
      setNotification({ ...notification, ...data });
    } catch (error) { }
  };

  const addNewNotification = async (notificationData: NotificationModel) => {
    try {
      const newRecords = [notificationData, ...notification.records];
      setNotification({
        ...notification,
        records: newRecords,
        totalRecords: notification.totalRecords + 1,
        unread: notification.unread + 1,
      });
    } catch (error) { }
  };

  const readNotification = async (id: number) => {
    try {
      const newRecords = notification.records.map((record) => {
        return record.id === id ? { ...record, isRead: true } : record;
      });
      setNotification({
        ...notification,
        records: newRecords,
        unread: notification.unread - 1,
      });
      await NotificationApi.readNotification(id);
    } catch (error) {
      console.log(error);
    }
  };

  const readAllNotification = async () => {
    try {
      await NotificationApi.readAllNotification();
      await getListNotifications();
    } catch (error) { }
  };

  const clearAllNotification = async () => {
    try {
      await NotificationApi.clearAllNotification();
      setNotification({
        ...notification,
        records: [],
        totalRecords: 0,
        unread: 0,
      });
    } catch (error) { }
  };
  //service

  const createContent = async (
    clientId?: number,
    isEdit?: boolean,
    contentId?: number
  ) => {
    let {
      isSyncContent,
      tags,
      status,
      platformContents,
      scheduleOn,
      requireApproval,
    } = contentValue;
    const request: CreateContentRequest = {
      clientId,
      isSyncContent,
      tagIds: tags.map((tag) => tag.id),
      submitType:
        status === PostStatusType.Error ? SubmitType.Publish : status,
      scheduleOn:
        status === SubmitType.Schedule
          ? DateUtils.getDateWithTimezone(
            scheduleOn,
            client?.timeZoneInfo?.id
          )
            .utc()
            .format()
          : undefined,
      requireApproval,
      platformContents:
        platformContents
          .filter((p) => {
            if (isSyncContent && p.isReels) return false;
            if (p.isReels && !p.post && !p.medias?.length) return false; // if reel has bank content -> not save
            return !p.disabled;
          })
          .map((p) => {
            const youtubeSetting = p.platform === SocialType.Youtube ? {
              categoryId: p.categoryId,
              playListId: p.playListId,
              makeForKid: p.makeForKid,
              visibility: p.visibility,
              youtubeTagsList: p.youtubeTagsList,
            } : undefined;
            return {
              title: p.title,
              post: p.post,
              medias: p.medias,
              platform: p.platform,
              isReels: p.isReels,
              shareReelsFeed: p.shareReelsFeed,
              ...youtubeSetting,
            };
          }) || [],
    };

    return !isEdit
      ? await SocialApi.createContent(request)
      : await SocialApi.editContent({
        ...request,
        id: contentId,
        requireApproval: undefined,
      });
  };

  const getListContent = async (
    request?: GetListContentRequestModel,
    isCalendar?: boolean
  ) => {
    const [contentData, setContent] = isCalendar
      ? [contentCalendarState, setContentCalendarState]
      : [contentState, setContentState];
    const { query } = contentData;
    setContent({ ...contentData, loading: true });
    try {
      const formData = { ...query, ...request };
      const { data } = await SocialApi.getContent(formData);
      setContent({
        ...contentData,
        ...data,
        query: formData,
        loading: false,
      });
    } catch (error) {
      console.log(error);
      setContent({ ...contentData, loading: false });
    }
  };

  const getListContentCalendar = async (
    request?: GetListContentRequestModel
  ) => {
    const { query } = contentState;
    const statuses = request?.statuses || query.statuses;
    const newStatus = statuses?.length
      ? statuses
      : [PostStatusType.Error, PostStatusType.Posted, PostStatusType.Scheduled];
    await getListContent({ ...request, statuses: newStatus }, true);
  };

  const getListTag = async (clientId: number) => {
    try {
      const { data } = await SocialApi.getListTag({ clientId });
      setListTag(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getListPostType = async (clientId: number) => {
    try {
      const { data } = await SocialApi.getPostTypeContent({ clientId });
      setListPostType(data);
    } catch (error) {
      console.log(error);
    }
  };

  return {
    NotificationService: {
      getListNotifications,
      addNewNotification,
      readNotification,
      readAllNotification,
      clearAllNotification,
    },
    SocialService: {
      createContent,
      getListContent,
      getListContentCalendar,
      getListTag,
      getListPostType,
    },
  };
};

export default useService;
