import { useRecoilState, useRecoilValue } from "recoil";
import {
  LIMIT_CHARACTER_FB,
  LIMIT_CHARACTER_GOOGLE,
  LIMIT_CHARACTER_INS,
  LIMIT_CHARACTER_TIKTOK,
  LIMIT_CHARACTER_YOUTUBE,
  LIMIT_CHARACTER_YOUTUBE_TITLE,
} from "../../../../../../../constants/app-constants";
import {
  SocialExtendReelType,
  SocialReelType,
  SocialType,
} from "../../../../../../../constants/app.enums";
import {
  contentValueState,
  currentContentSelector,
  listPlatformContentSelector,
} from "../../../../../../../states/social";
import SVGIcons from "../../../../../../icons/svgs";

const LimitCharacter = () => {
  const currentContent = useRecoilValue(currentContentSelector);
  const listPlatformContent = useRecoilValue(listPlatformContentSelector);
  const [contentValue, setContentValue] = useRecoilState(contentValueState);
  const { isSyncContent, currentSocial } = contentValue;

  const isShow = (socialType: SocialExtendReelType) => {
    const isDisable = listPlatformContent.find(
      (content) => content.platformExtendReel === socialType
    )?.disabled;
    if (isDisable == undefined) return false;
    return isDisable ? false : isSyncContent || currentSocial === socialType;
  };
  return (
    <>
      {(isShow(SocialType.Facebook) || isShow(SocialReelType.FacebookReel)) && (
        <div
          className={`limit-character ${
            LIMIT_CHARACTER_FB - (currentContent?.post || "").length < 0
              ? "over-limit"
              : ""
          }`}
        >
          <SVGIcons.BrandFacebookIcon />{" "}
          <span>
            {LIMIT_CHARACTER_FB - (currentContent?.post || "").length}
          </span>
        </div>
      )}
      {(isShow(SocialType.Instagram) ||
        isShow(SocialReelType.InstagramReel)) && (
        <div
          className={`limit-character ${
            LIMIT_CHARACTER_INS - (currentContent?.post || "").length < 0
              ? "over-limit"
              : ""
          }`}
        >
          <SVGIcons.BrandInstagramIcon />{" "}
          <span>
            {LIMIT_CHARACTER_INS - (currentContent?.post || "").length}
          </span>
        </div>
      )}
      {isShow(SocialType.Tiktok) && (
        <div
          className={`limit-character ${
            LIMIT_CHARACTER_TIKTOK - (currentContent?.post || "").length < 0
              ? "over-limit"
              : ""
          }`}
        >
          <SVGIcons.BrandTiktokIcon />{" "}
          <span>
            {LIMIT_CHARACTER_TIKTOK - (currentContent?.post || "").length}
          </span>
        </div>
      )}
      {(isShow(SocialType.Youtube) || isShow(SocialReelType.YoutubeShort)) && (
        <div
          className={`limit-character ${
            LIMIT_CHARACTER_YOUTUBE_TITLE - ((isSyncContent ? currentContent?.post : currentContent?.title) || "").length < 0
              ? "over-limit"
              : ""
          }`}
        >
          <SVGIcons.BrandYoutubeIcon />{" "}
          <span>
            {LIMIT_CHARACTER_YOUTUBE_TITLE - ((isSyncContent ? currentContent?.post : currentContent?.title) || "").length}
          </span>
        </div>
      )}
      {!isSyncContent && (isShow(SocialType.Youtube) || isShow(SocialReelType.YoutubeShort)) && (
        <div
          className={`limit-character ${
            LIMIT_CHARACTER_YOUTUBE - (currentContent?.post || "").length < 0
              ? "over-limit"
              : ""
          }`}
        >
          <SVGIcons.BrandYoutubeIcon />{" "}
          <span>
            {LIMIT_CHARACTER_YOUTUBE - (currentContent?.post || "").length}
          </span>
        </div>
      )}
      {isShow(SocialType.Google) && (
        <div
          className={`limit-character ${
            LIMIT_CHARACTER_GOOGLE - (currentContent?.post || "").length < 0
              ? "over-limit"
              : ""
          }`}
        >
          <SVGIcons.BrandGoogleIcon />{" "}
          <span>
            {LIMIT_CHARACTER_GOOGLE - (currentContent?.post || "").length}
          </span>
        </div>
      )}
    </>
  );
};

export default LimitCharacter;
