import { Button, Divider, InputNumber, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthApi } from "../../../../apis/auth.api";
import { ROUTE_PATHS } from "../../../../constants/router.constants";
import useClient from "../../../../hooks/useClient";
import useFetchConnection from "../../../../hooks/useFetchConnection";
import IntegrationCollapse from "../../../common/integration-collapse";
import showNotification from "../../../common/notification";
import SVGIcons from "../../../icons/svgs";
import AddConnection from "../../client-sites/intergration-form/add-connection";
import "./index.scss";

function GeneralSettingAdvanced() {
  const { t } = useTranslation();
  const [averageValue, setAverageValue] = useState(0);
  const [timeZoneSelected, setTimeZoneSelected] = useState<string | undefined>(
    undefined
  );
  const { fetchConnection } = useFetchConnection();

  const { client, setClient, clientId, isOwner } = useClient();
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    updateValueFromClient();
  }, [client]);

  function updateValueFromClient() {
    setAverageValue(client?.averageValueOfNewCustomer || 0);
    setTimeZoneSelected(client?.timeZoneInfo?.id);
  }

  const onChangeValue = (value: number) => {
    setAverageValue(value);
  };

  const onClickCancel = () => {
    updateValueFromClient();
  };

  const onClickSave = async () => {
      setSubmitLoading(true);
      await saveAdvancedSettings();
      setSubmitLoading(false);
  };


  useEffect(()=> {
    if(clientId) {
      fetchConnection();
    }
  }, [clientId])

  const saveAdvancedSettings = async () => {
    try {
      await AuthApi.updateAverageValueOfNewCustomer(
        client?.id,
        averageValue
      );
      if (client) {
        setClient({
          ...client,
          averageValueOfNewCustomer: averageValue,
        });
        showNotification("success", t(`common.changeSaveSuccess`));
      }
    } catch {}
  };

  return (
    <div className="general-setting-advanced">
      <div className="advanced-setting">
        <div className="title">
          <span>{t("generalSettings.dashboardSettings")}</span>
          <div className="connect-apps">
            <div className="label">
              {t("generalSettings.dashboardConnectApps")}{" "}
              <a
                href={ROUTE_PATHS.Docs.SetUpDashboard}
                target="_blank"
                className="learn-more"
              >
                {t("generalSettings.learnMore")}
              </a>
            </div>
          </div>
        </div>
        {
          isOwner() && <AddConnection />
        }
        
      </div>

      {clientId && <IntegrationCollapse clientId={clientId} />}

      <div className="title">
        <span>{t("generalSettings.returnOnInvestment")}</span>
      </div>
      <div className="average-value">
        <div className="label">{t("generalSettings.averageValue")}</div>
        <div className="average-value__input">
          <InputNumber
            addonBefore={<SVGIcons.DollarIcon />}
            value={averageValue}
            onChange={(value) => onChangeValue(value || 0)}
            disabled={!client || !isOwner()}
          />
          <Tooltip
            overlayClassName="custom-tooltip"
            placement="top"
            title={t("generalSettings.averageValueTooltip")}
          >
            <div className="icon">
              <span>?</span>
            </div>
          </Tooltip>

          <Button
            disabled={!isOwner()}
            className="mid-night-border reset-btn"
            onClick={onClickCancel}
          >
            {t("common.reset")}
          </Button>
        </div>
      </div>
      <Divider />
      <div className="save-btn">
        <Button
          onClick={onClickSave}
          type="primary"
          loading={submitLoading}
          disabled={
            !client ||
            (client.averageValueOfNewCustomer === averageValue &&
              client.timeZoneInfo?.id === timeZoneSelected)
          }
        >
          {t("common.saveChanges")}
        </Button>
      </div>
    </div>
  );
}

export default GeneralSettingAdvanced;
