import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import HeaderImage from "../../../assets/images/header-background.png";
import {
  AdminViewSitePrefixRouter,
  ROUTE_PATHS
} from "../../../constants/router.constants";
import { useBasePath } from "../../../hooks/useBasePath";
import useClient from "../../../hooks/useClient";
import useClientPlan from "../../../hooks/useClientPlan";
import { navbarOpenState, windowsWidthState } from "../../../states/common";
import DateUtils from "../../../utils/date.utils";
import Countdown from "../countdown";
import LimitedModal from "../countdown/limited-modal";
import UpgradeModal from "../countdown/upgrade-modal";
import MainHeader from "../main-header";
import SecondHeader from "../second-header";
import "./index.scss";
import LimitedStorageModal from "../countdown/limited-storage-modal";

function MainLayout() {
  const pathname = useBasePath();
  const menuOpen = useRecoilValue(navbarOpenState);
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenLimited, setIsOpenLimited] = useState<boolean>(false);
  const [isOpenStorageLimited, setIsOpenStorageLimited] = useState<boolean>(false);
  const {setExpiredPlan,  isShowUpgradeModal, isShowLimitedTrialPlanModal, isShowLimitedStorageModal,  resetShowLimitedModal, resetShowLimitedStorageModal, resetShowUpgradeModal, isPlanExpired} = useClientPlan()
  const { client } = useClient();

  const isDashboardLayout =
    pathname &&
    (pathname === ROUTE_PATHS.Home || pathname === AdminViewSitePrefixRouter);
  const setWidth = useSetRecoilState(windowsWidthState);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    if (isShowLimitedTrialPlanModal) {
      setIsOpenLimited(true)
      resetShowLimitedModal()
    }
  }, [isShowLimitedTrialPlanModal]);

  useEffect(() => {
    if (isShowLimitedStorageModal) {
      setIsOpenStorageLimited(true)
      resetShowLimitedStorageModal()
    }
  }, [isShowLimitedStorageModal]);

  const checkExpiredPlan = () => {
    const currentDate = moment();
    const expiredTime = moment(DateUtils.addUTCCharater(client?.userPlan?.expiredTime))
    if (expiredTime < currentDate) {
      setExpiredPlan()
    }
  };

  useEffect(() => {
    if (isShowUpgradeModal && isPlanExpired) {
      setIsOpen(true)
      resetShowUpgradeModal()
    }
  }, [isShowUpgradeModal]);

  useEffect(()=> {
    checkExpiredPlan()
  }, [])

  return (
    <div
      className={`main-layout`}
    >
      <Countdown />
      <UpgradeModal isOpen={isOpen} setIsOpen={setIsOpen} />
      <LimitedModal isOpen={isOpenLimited} setIsOpen={setIsOpenLimited} />
      <LimitedStorageModal isOpen={isOpenStorageLimited} setIsOpen={setIsOpenStorageLimited} />


      <div className={`header-wrapper${isDashboardLayout ? ' header-overlay' : ''}`}
        style={{
          backgroundImage: `url(${HeaderImage})`,
        }} >
        <div className="page-container-width">
          <MainHeader />
        </div>
        <div className="line"></div>
        <div className={`page-container-width`}>
          <SecondHeader />
        </div>
        <div className={`line${menuOpen ? '' : ' mobile-hidden'}`}></div>
      </div>
      <div className={`page-container-width app-body`}>
        <Outlet />
      </div>
      <div className="app-footer">
        <small className="copy-right">{t("common.copyRight")}</small>
      </div>
    </div>
  );
}

export default MainLayout;
