import moment from "moment";
import { SocialApi } from "../apis/social.api";
import {
  AdminViewSitePrefixRouter,
  ROUTE_PATHS,
} from "../constants/router.constants";
import { CreateShortLinkRequest } from "../models/social.model";

const httpPrefix = "http://";
const httpsPrefix = "https://";

function secondsToHms(d?: number) {
  if (!d) {
    return "00:00";
  }

  d = Number(d);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  const hDisplay = h === 0 ? "" : h < 10 ? `0${h}:` : `${h}:`;
  const mDisplay = m < 10 ? `0${m}` : m.toString();
  const sDisplay = s < 10 ? `0${s}` : s.toString();
  return `${hDisplay}${mDisplay}:${sDisplay}`;
}

function removeProtocol(url: string | undefined) {
  if (!url) {
    return "";
  }

  if (url.startsWith(httpPrefix)) {
    url = url.replace(httpPrefix, "");
  } else if (url.startsWith(httpsPrefix)) {
    url = url.replace(httpsPrefix, "");
  }

  return url;
}

function urlAddBaseAddress(url: string | undefined) {
  if (!url) {
    return "";
  }

  if (url.includes("//")) {
    return url;
  }

  return "//" + url;
}

function numberSorting(a: number, b: number): number {
  return a - b;
}

function stringSorting(a: string | undefined, b: string | undefined): number {
  if (!a) {
    a = "";
  }

  if (!b) {
    b = "";
  }
  return a.localeCompare(b);
}

function dateSorting(a: string | undefined, b: string | undefined): number {
  return moment(a).unix() - moment(b).unix();
}

function getAdminViewClientSiteRouter(
  clientRouter: string,
  clientId: number
): string {
  return clientRouter === ROUTE_PATHS.Home
    ? `${AdminViewSitePrefixRouter}/${clientId}`
    : `${AdminViewSitePrefixRouter}${clientRouter}/${clientId}`;
}

function deepCopy<T>(object: T): T {
  return JSON.parse(JSON.stringify(object));
}

function queryParamReplaceSpace(param: string) {
  if (param && param.indexOf(" ") !== -1) {
    param = param.replace(" ", "+");
  }

  return param;
}

async function everyAsync<T>(
  arr: T[],
  cb: (item: T) => Promise<boolean>
): Promise<boolean> {
  for (let index = 0; index < arr.length; index++) {
    if (!(await cb(arr[index]))) {
      return false;
    }
  }
  return true;
}

async function shortenUrl(request: CreateShortLinkRequest) {
  try {
    const response = await SocialApi.createShortLink(request)
    return response.data;
  } catch (error) {
    return null
  }
};

function formatDuration(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs =  Math.floor(seconds % 60);

  const pad = (num: number) => String(num).padStart(2, '0');

  // Nếu có giờ thì hiển thị dưới định dạng hh:mm:ss, nếu không thì chỉ hiển thị mm:ss
  if (hours > 0) {
    return `${hours}:${pad(minutes)}:${pad(secs)}`;
  } else {
    return `${pad(minutes)}:${pad(secs)}`;
  }
}

function formatFileSize(bytes: number) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
}

export const CommonUtils = {
  secondsToHms,
  removeProtocol,
  urlAddBaseAddress,
  numberSorting,
  stringSorting,
  dateSorting,
  getAdminViewClientSiteRouter,
  deepCopy,
  queryParamReplaceSpace,
  everyAsync,
  shortenUrl,
  formatDuration,
  formatFileSize
};
