import { Button, Dropdown, Space, Switch, Tabs, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import AppDateTimePicker from "../../../../controls/app-date-time-picker";
import SVGIcons from "../../../../icons/svgs";
import AvatarSocial from "../components/avatar-social";
import ContentEditor from "../create-content/content-editor";
import FacebookPNG from "../../../../../assets/images/app-icon-facebook.png";
import InstagramPNG from "../../../../../assets/images/app-icon-instag.png";
import TiktokPNG from "../../../../../assets/images/app-icon-tiktok.png";
import YoutubeSVG from "../../../../../assets/icons/app-icon-youtube.svg";
import GoogleSVG from "../../../../../assets/icons/app-icon-google.svg";
import "./index.scss";
import ActivityTab from "./activity-tab";
import CommentTab from "./comment-tab";
import { ApprovalEditStatus } from "../components/approval-status";
import { useEffect, useRef, useState } from "react";
import {
  ApprovalStatusEnum,
  PermissionSocial,
  PlatformStatusType,
  PostStatusType,
  SocialReelType,
  SocialType,
  SubmitType,
} from "../../../../../constants/app.enums";
import { ContentModel } from "../../../../../models/content.model";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  contentValueState,
  currentContentSelector,
  listPlatformContentSelector,
  listProfileSelector,
  SocialContentState,
} from "../../../../../states/social";
import useUpdateContent from "../hook/useUpdateContent";
import useClient from "../../../../../hooks/useClient";
import { NetworkModel } from "../../../../../models/social.model";
import ShareLinkModal from "../../share-link-modal";
import { ROUTE_PATHS } from "../../../../../constants/router.constants";
import DateUtils from "../../../../../utils/date.utils";
import { SocialTypes } from "../../../../../constants/social.constants";
import SocialUtils from "../../../../../utils/social";
import { isMobileSelector } from "../../../../../states/common";
import ContentTab from "../components/content-tab";

interface EditContentProps {
  contentInfo: ContentModel;
  onEditContent: (contentId: number) => Promise<void>;
  onEditApprovalContent?: (
    contentId: number,
    approvalStatus: ApprovalStatusEnum
  ) => void;
  isReviewing?: boolean;
  isApprovalRequest?: boolean;
  defaultProfile?: NetworkModel[];
}

const getSocialImage = (platForm?: SocialType) => {
  switch (platForm) {
    case SocialType.Facebook:
      return FacebookPNG;
    case SocialType.Instagram:
      return InstagramPNG;
    case SocialType.Tiktok:
      return TiktokPNG;
    case SocialType.Youtube:
      return YoutubeSVG;
    case SocialType.Google:
      return GoogleSVG;
    default:
      return FacebookPNG;
  }
};

const EditContent = (props: EditContentProps) => {
  const {
    contentInfo,
    onEditContent,
    onEditApprovalContent,
    isReviewing,
    isApprovalRequest,
    defaultProfile,
  } = props;
  const { t } = useTranslation();
  const {
    onChangeMenu,
    onChangeDate,
    renderModeSubmit,
    onChangeStatusAvatar,
    onChangeSync,
  } = useUpdateContent();
  const { hasPermissionSocial, onlyHasPermissionSocial } = useClient();
  const [contentValue, setContentValue] = useRecoilState(contentValueState);
  const listPlatformContent = useRecoilValue(listPlatformContentSelector);
  const listProfile = useRecoilValue(listProfileSelector);
  const currentContent = useRecoilValue(currentContentSelector);
  const isMobile = useRecoilValue(isMobileSelector);

  const [contentShareLink, setContentShareLink] = useState<ContentModel>();
  const { clientId, client } = useClient();

  const url = `${window.location.origin}${ROUTE_PATHS.Social.ReviewContent}?contentId=${contentShareLink?.id}&clientId=${clientId}`;
  const { platformContents, id, status: statusContent } = contentInfo;
  const {
    isSyncContent,
    status,
    loadingSubmit,
    scheduleOn,
    approvalStatus,
    currentSocial,
  } = contentValue;
  const isPublished = PostStatusType.Posted === statusContent;
  const isContentError = PostStatusType.Error === statusContent;

  const currentProfiles = defaultProfile || listProfile;

  const commentTabRef = useRef<any>();

  const canEditContent =
    !isReviewing &&
    !isPublished &&
    hasPermissionSocial(PermissionSocial.CanManage);

  const canApproveContent =
    !isPublished && hasPermissionSocial(PermissionSocial.CanApprove);

  const canEditCurrentContent =
    !isReviewing &&
    currentContent?.status !== PlatformStatusType.Success &&
    hasPermissionSocial(PermissionSocial.CanManage);

  const onChangeApproval = (status: ApprovalStatusEnum) => {
    setContentValue({ ...contentValue, approvalStatus: status });
    onEditApprovalContent && onEditApprovalContent(contentInfo.id, status);

    if (status === ApprovalStatusEnum.Declined) {
      commentTabRef.current.reloadComment();
    }
  };

  const onChangePlatform = (value: any) => {
    setContentValue({ ...contentValue, currentSocial: parseInt(value) });
  };

  const onChangeStatus = (content: SocialContentState) => {
    canEditContent && content && onChangeStatusAvatar(content);
    isReviewing && onChangePlatform(content?.platform);
  };

  useEffect(() => {
    let firstSocialEnable: any = undefined;
    const listContentDefault: SocialContentState[] = SocialTypes.reduce(
      (result: SocialContentState[], platform: SocialType) => {
        const content = platformContents.find((p) => p.platform === platform);
        const profile = currentProfiles.find(
          (profile) => profile.platform === platform
        );
        if (firstSocialEnable === null) {
          if (contentInfo.status === PostStatusType.Error) {
            // show first social error if content error
            if (content?.status === PlatformStatusType.Error)
              firstSocialEnable = profile?.platform;
          } else if (content) firstSocialEnable = profile?.platform;
        }

        // add reelContent if fb, insta, youtube
        let reelContent: SocialContentState[] = [];
        if ([SocialType.Facebook, SocialType.Instagram, SocialType.Youtube].includes(platform)) {
          const contentReel = platformContents.find(
            (p) => p.platform === platform && (p.isReels || contentInfo.isSyncContent)
          );
          reelContent = [
            {
              ...contentReel,
              disabled: content ? false : true,
              profile,
              platform: platform,
              isReels: true,
              platformExtendReel: platform + 10,
                // platform === SocialType.Facebook
                //   ? SocialReelType.FacebookReel
                //   : SocialReelType.InstagramReel,
            },
          ];
        }

        return [
          ...result,
          {
            ...content,
            disabled: content ? false : true,
            profile,
            platform,
            platformExtendReel: platform,
          },
          ...reelContent,
        ];
      },
      []
    );

    setContentValue({
      ...(contentInfo as any),
      platformContents: listContentDefault,
      currentSocial:
        firstSocialEnable !== undefined
          ? firstSocialEnable
          : listContentDefault.find((x) => !x.disabled)?.platform,
      isSyncContent:
        contentInfo.status === PostStatusType.Error
          ? false
          : contentInfo.isSyncContent,
      status:
        contentInfo.status === PostStatusType.Posted
          ? PostStatusType.Drafted
          : contentInfo.status,
    });
  }, [contentInfo]);

  const isShowDropdownMode =
    !isPublished && canEditContent && !!currentProfiles.length;

  return (
    <div className="edit-content-container">
      <div className="edit-content-container-header">
        <div className="edit-content-container-header__left">
          <Space
            style={{ rowGap: isMobile ? 16 : 8, columnGap: isMobile ? 16 : 8 }}
          >
            {listPlatformContent
              .filter((content) => {
                if (content.isReels) return false;
                return (
                  (canEditContent && !isContentError) || !content?.disabled
                );
              })
              .map((content) => {
                return (
                  <AvatarSocial
                    avatar={getSocialImage(content?.platform)}
                    onChangeStatus={() => content && onChangeStatus(content)}
                    size={isMobile ? 40 : 32}
                    sizeIcon={14}
                    key={content?.platform}
                    disabled={
                      content?.disabled ||
                      (isReviewing &&
                        !isSyncContent &&
                        currentSocial % 10 !== content?.platform) // fb reel is 10 % 10 = 0 (fb)
                    }
                    tooltip={
                      content?.profile?.name ||
                      `Your ${SocialUtils.getSocialName(
                        content.platform
                      )} profile`
                    }
                    status={content?.status}
                    isReviewing={isReviewing}
                  />
                );
              })}
          </Space>
        </div>
        {!isReviewing && (
          <div className="edit-content-container-header__right">
            <div className="sync-content">
              <span>{t("social.content.syncContent")}</span>
              <Switch
                checked={isSyncContent}
                onChange={onChangeSync}
                disabled={!canEditContent}
              ></Switch>
            </div>
          </div>
        )}
      </div>
      <div className="edit-content-container-body">
        <div className="edit-content-container-body__left">
          <div className="sharelink-approval">
            {!isReviewing && (
              <Tooltip title={t("social.content.tooltipShareLink")}>
                <div
                  className="share-link"
                  onClick={() => setContentShareLink(contentInfo)}
                >
                  <div className="icon">
                    <SVGIcons.LinkIcon />
                  </div>
                </div>
              </Tooltip>
            )}
            {canApproveContent && !isReviewing && (
              // use for edit content
              <ApprovalEditStatus
                approvalStatus={approvalStatus || ApprovalStatusEnum.None}
                onChangeStatus={onChangeApproval}
                contentId={id}
              />
            )}
            {isApprovalRequest && (
              // use for approval status
              <ApprovalEditStatus
                approvalStatus={approvalStatus || ApprovalStatusEnum.None}
                onChangeStatus={onChangeApproval}
                contentId={id}
              />
            )}
          </div>

          {!isReviewing && <div className="content-tab">
            <ContentTab />
          </div>}
        </div>
        <div className="edit-content-container-body__middle">
          <ContentEditor
            contentInfo={contentInfo}
            isReviewing={isReviewing}
            canEditContent={canEditContent}
            canEditCurrentContent={canEditCurrentContent}
          />
          <div className="create-content-modal-footer">
            {status === SubmitType.Schedule ? (
              <AppDateTimePicker
                onChange={onChangeDate}
                defaulValue={DateUtils.getDateWithTimezone(
                  scheduleOn,
                  client?.timeZoneInfo?.id
                )}
                readonly={
                  isReviewing ||
                  onlyHasPermissionSocial(PermissionSocial.CanApprove)
                }
              />
            ) : (
              <div></div>
            )}
            <div className="btn-group-save">
              {hasPermissionSocial(PermissionSocial.CanManage) &&
                !isReviewing && (
                  <Button
                    type="primary"
                    className={`btn-save ${
                      isShowDropdownMode ? "" : "full-radius"
                    }`}
                    onClick={() => onEditContent(id)}
                    loading={loadingSubmit}
                    disabled={
                      isPublished &&
                      JSON.stringify(contentInfo.tags) ===
                        JSON.stringify(contentValue.tags)
                    }
                  >
                    {renderModeSubmit(isPublished)}
                  </Button>
                )}
              {isShowDropdownMode && (
                <Dropdown
                  menu={{
                    items: [
                      { key: SubmitType.Save, label: "Save" },
                      {
                        key: SubmitType.Schedule,
                        label: "Schedule",
                        ishidden: isContentError.toString(),
                      },
                      {
                        key: isContentError
                          ? PostStatusType.Error
                          : SubmitType.Publish,
                        label: "Publish Now",
                        ishidden: (
                          (onlyHasPermissionSocial(
                            PermissionSocial.CanManage
                          ) &&
                            approvalStatus !== ApprovalStatusEnum.None) ||
                          approvalStatus === ApprovalStatusEnum.Declined
                        ).toString(),
                      },
                    ].filter(
                      (item) => item.key !== status && item.ishidden !== "true"
                    ),
                    onClick: (item) => onChangeMenu(item.key),
                  }}
                  trigger={["click"]}
                  disabled={loadingSubmit}
                  placement="bottomRight"
                >
                  <Button type="primary" className="btn-dropdown">
                    <SVGIcons.ChevronDown />
                  </Button>
                </Dropdown>
              )}
            </div>
          </div>
        </div>
        <div className="edit-content-container-body__right">
          {isReviewing ? (
            <CommentTab
              showViewAllButton={true}
              isReviewing={isReviewing}
              ref={commentTabRef}
              contentInfo={contentInfo}
            />
          ) : (
            <Tabs
              defaultActiveKey="1"
              type="card"
              items={[
                {
                  label: "COMMENT",
                  key: "1",
                  children: (
                    <CommentTab showViewAllButton={true} ref={commentTabRef} />
                  ),
                },
                {
                  label: "ACTIVITY",
                  key: "2",
                  children: <ActivityTab />,
                },
              ]}
            />
          )}
        </div>
      </div>

      <ShareLinkModal
        content={contentShareLink}
        close={() => {
          setContentShareLink(undefined);
        }}
        url={url}
      />
    </div>
  );
};

export default EditContent;
