export enum DateRangeValues {
  last7days = "1",
  lastMonth = "2",
  last3Months = "3",
  lastYear = "4",
  customRange = "5",
  lastMonthSocial = "6",
}

export enum IntegrationStatus {
  None,
  Success,
  Error,
  SyncSuccess,
  SyncError,
  ConnectionLostError,
  ConnectionGALostError,
}

export enum DeliveryFrequency {
  Daily = 0,
  Weekly = 1,
  Monthly = 2,
  Quarterly = 3,
  Yearly = 4,
  DoNotSend = 5,
}

export enum ReportFormat {
  CSV = 0,
  PDF = 1,
}

export enum IntegrationType {
  BEIntegrations = "beIntegrations",
  CRIntegrations = "crIntegrations",
  GAIntegrations = "gaIntegrations",
  GFIntegrations = "gfIntegrations",
  GMBIntegrations = "gmbIntegrations",
}

export enum DetailedMessage {
  NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED",
}

export enum SocialType {
  Facebook = 0,
  Instagram = 1,
  Tiktok = 2,
  Youtube = 4,
  Google = 3,
}
export enum SocialReelType {
  FacebookReel = 10,
  InstagramReel = 11,
  YoutubeShort = 14,
}

export type SocialExtendReelType = SocialType | SocialReelType;

export enum ColorTag {
  Blue = "#0185de",
  Malibu = "#5cbae6",
  Green = "#9bc13c",
  Parakeet = "#2fb344",
  Orange = "#f59f00",
  Sunset = "#f35848",
  Steel = "#738fbd",
  Nobel = "#9b9b9b",
}

export enum ClientCategory {
  Uncategorised = 0,
  Automotive = 1,
  Dental = 2,
  Legal = 3,
  HealthAndBeauty = 4, // Health & Beauty
  IndependentConsultant = 5, // Independent Consultant
  Medical = 6,
  Education = 7,
  Logistics = 8,
  HomeServices = 9, // Home Services
  RealEstate = 10, //Real Estate
  FinancingAndInsurance = 11, //Financing & Insurance
  InformationTechnologies = 12, //Information Technologies
  Marketing = 13,
  TravelAgency = 14, //Travel Agency
  Industrial = 15,
  Construction = 16,
  AnimalIndustry = 17, //Animal Industry
}

// export enum ClientCategoryMapping {
//   Uncategorised = 0,
//   Automotive = 1,
//   Dental = 2,
//   Legal = 3,
//   "Health & Beauty" = 4, // Health & Beauty
//   "Independent Consultant" = 5, // Independent Consultant
//   Medical = 6,
//   Education = 7,
//   Logistics = 8,
//   "Home Services" = 9, // Home Services
//   "Real Estate" = 10, //Real Estate
//   "Financing & Insurance" = 11, //Financing & Insurance
//   "Information Technologies" = 12, //Information Technologies
//   Marketing = 13,
//   "Travel Agency" = 14, //Travel Agency
//   Industrial = 15,
//   Construction = 16,
//   "Animal Industry" = 17 //Animal Industry
// }

export enum BulkActionType {
  DELETE = "delete",
  MOVE = "move",
}

export enum MenuActionContent {
  EDIT = "edit",
  PUBLISH = "publish",
  SCHEDULE = "schedule",
  SHARE = "share",
  DELETE = "delete",
}

export enum ActivityType {
  CREATED = 0,
  EDITED = 1,
  PUBLISHED = 2,
  SCHEDULE = 3,
  ERROR = 4,
  APPROVED = 5,
  DECLINED = 6,
  APPROVAL_TURN_ON = 7,
  APPROVAL_NOT_REQUIRE = 8,
  SCHEDULED_PUBLISHING_ERROR = 9,
  ADDED_TO_APPROVAL = 10,
  EXPIRED,
  UNKNOWN,
}

export enum ApprovalActivityType {
  Create,
  Update,
  ChangeDueDate,
  OneApprove,
  OneDecline,
  ApproversNotified,
  ApproveAllEnable,
  ApproveAllModify,
  AllContentsApproved,
  AllContentsDeclined,
}

export enum SubmitType {
  Save = 0,
  Schedule = 1,
  Publish = 2,
}

export enum NotificationDeviceType {
  /// <summary>
  /// Website
  /// </summary>
  Web = 0,

  /// <summary>
  /// Android
  /// </summary>
  Android = 1,

  /// <summary>
  /// Ios
  /// </summary>
  IOS = 2,
}

export enum IntegrationPanelKey {
  GoogleAnalytics = "0",
  GoogleMyBusiness = "1",
  Birdeye = "2",
  CallRail = "3",
  GravityForm = "4",
}

export enum NotificationType {
  Info = 0,
  Warning = 1,
  Error = 2,
}

export enum NotificationActionType {
  PublishContentSuccess = 1,
  PublishContentFailure = 2,
  ScheduleContent = 3,
}

export enum PlatformContentKeyMapping {
  facebookContent = 0,
  instagramContent = 1,
  tiktokContent = 2,
}

export enum PostStatusType {
  Drafted = 0,
  Scheduled = 1,
  Posted = 2,
  Error = 3,
}

export enum PostType {
  ImageShortCaptionHashtagQuestionLink = 234,
  ImageLongCaptionHashtagLink = 114,
  VideoLongCaptionHashtagLink = 116,
  VideoShortCaptionHashtag = 44,
  StatusPost = 1,
}

export enum ServiceType {
  Social = 1,
}

export enum ServicePanelKey {
  Social,
  SocialDisable,
  Example,
  ExampleDisable,
}

export enum ContentErrorType {
  RequiredPost,
  RequiredPostFB,
  RequiredPostInsta,
  RequiredPostTiktok,
  RequiredPostYoutube,
  RequiredMediaFB,
  RequiredMediaIns,
  RequiredMediaFbReel,
  RequiredMediaInsReel,
  RequiredMediaTiktok,
  RequiredMediaYoutube,
  RequiredMediaYoutubeShort,
  LimitPostFB,
  LimitPostIns,
  LimitPostTiktok,
  LimitPostYoutube,
  LimitReel,
}

export enum PermissionSocial {
  CanManage,
  CanApprove,
}

export enum PlatformStatusType {
  Success = "success",
  Error = "error",
}

export enum ApprovalStatusEnum {
  None,
  Pending,
  Approved,
  Declined,
  NotRequied,
}

export enum BulkApprovalsStatus {
  Pending = 0,
  Approved = 1,
  Declined = 2,
  Expired = 3,
}

export enum PostTypeDashboardEnum {
  // Main type
  None = 0,
  Status = 1,
  Image = 2,
  Video = 4,
  Reels = 8,

  // Additional elements
  ShortCaption = 16,
  LongCaption = 32,
  Hashtag = 64,
  Link = 128,
  Question = 256,
}

export enum ReportType {
  General = 1,
  Social = 2,
}

export enum ClientStatus {
  Active = "active",
  Error = "error",
  Disabled = "deactive",
  PendingDeletion = "pendingdeletion",
  Canceled = "canceled",
}

export enum PlanType {
  Free = 0,
  Trial  = 1,
  Premium   = 2,
  None   = 3,
}

export enum CycleType {
  None = 0,
  Weekly = 1,
  Monthly = 2,
}

export enum UserPlan {
  FreeTrial = "FREE TRIAL",
  Premium = "PREMIUM",
  FreePlan = "FREE PLAN",
  None = "NONE",
}

export enum UserPlanStatus {
  New = 0,
  Active = 1,
  PendingCancel = 2,
  Canceled = 3,
  InActive = 4,
  Error = 5,
}

export enum LimitedTrialPlanType {
  Companies = "Companies",
  TeamMembers = "Team Members",
  PostsPerCompany = "Posts per Company",
  Reports = "Reports",
  Ai = "AI Credits",
}

export enum PlanUsageType {
  Company = 0,
  TeamMember = 1,
  PostPerCom = 2,
  ReportPerCom = 3,
  TotalPostMonthly = 4,
  Storage = 5,
}

export enum AITone {
  Polite = 0,
  Funny = 1,
  Enthusiastic = 2,
  Friendly = 3,
  Informational = 4,
  Witty = 5,
}

export enum PrivacySettingYoutubeEnum {
  Public,
  Unlisted,
  Private,
}