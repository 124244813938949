import { Alert } from "antd";
import { useTranslation } from "react-i18next";
import { IntegrationStatus } from "../../../../../constants/app.enums";
import SVGIcons from "../../../../icons/svgs";
import "./index.scss";

interface Props {
  status: IntegrationStatus;
  onReAuthenticate?: () => void;
}

function IntegrationAlert(props: Props) {
  const { status, onReAuthenticate } = props;
  const { t } = useTranslation();

  function renderContent() {
    switch (status) {
      case IntegrationStatus.Success:
        return (
          <Alert
            className="integration-form__alert"
            message={t("clientSites.integrationSuccessAlert")}
            type="success"
            showIcon
            icon={<SVGIcons.CheckedIcon />}
          />
        );
      case IntegrationStatus.Error:
        return (
          <Alert
            className="integration-form__alert"
            message={t("clientSites.integrationErrorAlert")}
            type="error"
            showIcon
            icon={<SVGIcons.ErrorIcon />}
          />
        );
      case IntegrationStatus.SyncSuccess:
        return (
          <Alert
            className="integration-form__alert"
            message={t("clientSites.integrationSyncSuccessAlert")}
            type="success"
            showIcon
            icon={<SVGIcons.CheckedIcon />}
          />
        );
      case IntegrationStatus.SyncError:
        return (
          <Alert
            className="integration-form__alert"
            message={t("clientSites.integrationSyncErrorAlert")}
            type="error"
            showIcon
            icon={<SVGIcons.ErrorIcon />}
          />
        );
      case IntegrationStatus.ConnectionGALostError:
        return (
          <Alert
            className="integration-form__alert"
            message={
              <>
                {t("clientSites.connectLostErrorAlert")}{" "}
                <span
                  className="reAuthen"
                  onClick={() => onReAuthenticate && onReAuthenticate()}
                >
                  {t("clientSites.pleaseReAuthenticate")}
                </span>
              </>
            }
            type="error"
            showIcon
            icon={<SVGIcons.ErrorIcon />}
          />
        );
      case IntegrationStatus.ConnectionLostError:
        return (
          <Alert
            className="integration-form__alert"
            message={t("clientSites.integrationConnectLostErrorAlert")}
            type="error"
            showIcon
            icon={<SVGIcons.ErrorIcon />}
          />
        );
      default:
        return "";
    }
  }

  return <>{renderContent()}</>;
}

export default IntegrationAlert;
