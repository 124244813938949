/* eslint-disable react/jsx-no-target-blank */
import { LoadingOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Spin } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { AuthServiceApi } from "../../../apis/auth.api";
import { ServiceType } from "../../../constants/app.enums";
import { ROUTE_PATHS } from "../../../constants/router.constants";
import useClient from "../../../hooks/useClient";
import { navbarOpenState } from "../../../states/common";
import { accessServiceState } from "../../../states/social";
import { CommonUtils } from "../../../utils/common";
import SVGIcons from "../../icons/svgs";
import "./index.scss";
import { GeneralSettingItems } from "../../../pages/general-settings";
import DateUtils from "../../../utils/date.utils";

function SecondHeader() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [accessServices, setAccessServices] =
    useRecoilState(accessServiceState);
  const navigate = useNavigate();
  const menuOpen = useRecoilValue(navbarOpenState);
  const [collapsing, setCollapsing] = useState(false);
  const [timeZoneOffset, setTimeZoneOffset] = useState<number>(0);

  const [currentTime, setCurrentTime] = useState<any>("");

  const { clientId, client, getRouter, isAdminViewSite } = useClient();
  useEffect(() => {
    setCollapsing(true);
    setTimeout(() => {
      setCollapsing(false);
    }, 1000);
  }, [menuOpen]);

  const onClickMenuSocial = ({ key }: any) => {
    navigate(`${getRouter(key)}`);
  };

  const menuSocial = (
    <Menu
      onClick={onClickMenuSocial}
      items={[
        { key: ROUTE_PATHS.Social.Dashboard, label: "Social Dashboard" },
        { key: ROUTE_PATHS.Social.Calendar, label: "Calendar" },
        { key: ROUTE_PATHS.Social.Content, label: "Content" },
        { key: ROUTE_PATHS.Social.Approvals, label: "Approvals" },
      ]}
    />
  );

  const menuItems = [
    {
      icon: <SVGIcons.Layout2Icon />,
      text: t("common.dashboard"),
      href: getRouter(ROUTE_PATHS.Home),
    },
    {
      icon: <SVGIcons.PolygonIcon />,
      text: (
        <Dropdown
          overlay={menuSocial}
          placement="bottom"
          overlayClassName="social-tab-dropdown"
        >
          <span className="social-dropdown">
            {t("common.social")}
            <SVGIcons.ChevronDown />
          </span>
        </Dropdown>
      ),
      href: getRouter(ROUTE_PATHS.Social.Dashboard),
      disabled: !accessServices.data.includes(ServiceType.Social),
    },
    {
      icon: <SVGIcons.ClipBoardTextIcon />,
      text: t("generalSettings.reports"),
      href: getRouter(ROUTE_PATHS.Reports),
    },
    {
      icon: <SVGIcons.TriangleSquareCircleIcon />,
      text: t("mediaLibrary.title"),
      href: getRouter(ROUTE_PATHS.MediaLibrary),
    },
    // {
    //   icon: <SVGIcons.Map2Icon />,
    //   text: t("common.listings"),
    //   href: ROUTE_PATHS.Listings,
    // },
    // {
    //   icon: <SVGIcons.StartIcon />,
    //   text: t("common.reviews"),
    //   href: ROUTE_PATHS.Reviews,
    // },
    // {
    //   icon: <SVGIcons.MessageIcon />,
    //   text: t("common.inbox"),
    //   href: ROUTE_PATHS.Inbox,
    // },
  ].filter((item) => !item.disabled);

  const onClickLink = (e: any, title: string) => {
    if (title === getRouter(ROUTE_PATHS.Social.Dashboard)) e.preventDefault();
  };

  const getAccessService = async () => {

    if (!clientId) return;
    const { data } = await AuthServiceApi.getAccessServices({
      clientId,
    });
    setAccessServices({ 
      data,
      isFetched: true
     });
  };

  function gotoCompanySettings() {
    const link = getRouter(ROUTE_PATHS.GeneralSettings);
    navigate(`${link}?tab=${GeneralSettingItems.company}`);
  }

  useEffect(() => {
    getAccessService();
  }, []);

  const getTimeByTimeZone = (nowByTimeZone: any) => {
    if (nowByTimeZone) {
      const timeByTimezone = moment(nowByTimeZone);
      const dayOfWeek = timeByTimezone.format("ddd");
      const dayOfMonth = timeByTimezone.format("D");
      const hours = parseInt(timeByTimezone.format("h"), 10);
      const minutes = timeByTimezone.format("m");
      const ampm = timeByTimezone.format("a");
      const gmtOffset =
        timeZoneOffset >= 0 ? `+${timeZoneOffset}` : timeZoneOffset;
      return `${dayOfWeek} ${dayOfMonth}, ${hours}:${minutes
        .toString()
        .padStart(2, "0")}${ampm} (UTC${gmtOffset})`;
    }
  };

  useEffect(() => {
    const displayTime = () => {
      if (client?.timeZoneInfo?.id) {
        const nowByTimeZone = DateUtils.convertTimeToTimezone(
          client?.timeZoneInfo?.id
        );
        setCurrentTime(nowByTimeZone);
        setTimeZoneOffset(Number(client?.timeZoneInfo?.offSet));
      } else {
        const userTimeZoneOffsetMinutes = new Date().getTimezoneOffset();
        const isNegative = userTimeZoneOffsetMinutes > 0;
        const userTimeZoneOffsetHours = Math.abs(
          Math.floor(userTimeZoneOffsetMinutes / 60)
        );
        const numericOffset = (isNegative ? -1 : 1) * userTimeZoneOffsetHours;

        const nowByTimeZone = DateUtils.convertTimeToTimezone();
        setCurrentTime(nowByTimeZone);
        setTimeZoneOffset(numericOffset);
      }
    };
    const intervalId = setInterval(displayTime, 1000);

    return () => clearInterval(intervalId);
  }, [client?.timeZoneInfo?.id, client?.timeZoneInfo?.offSet]);

  return (
    <>
      <div className={`second-header mobile-hidden`}>
        <div className="second-header__left">
          {menuItems.map((item, index) => (
            <Link
              to={item.href}
              onClick={(e) => onClickLink(e, item.href)}
              key={index}
              className={item.href && item.href === pathname ? "active" : ""}
            >
              {item.icon}
              <span>{item.text}</span>
            </Link>
          ))}
        </div>

        <div className="second-header__right">
          {
            <div className="second-header__time" onClick={gotoCompanySettings}>
              {currentTime ? (
                <>
                  <SVGIcons.ClockHour4 />
                  <span>{getTimeByTimeZone(currentTime)}</span>
                </>
              ) : (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />}
                />
              )}
            </div>
          }
          <a
            className="second-header__website"
            onClick={() => !isAdminViewSite && navigate(ROUTE_PATHS.SelectCompany)}
          >
            <SVGIcons.ListDetailIcon />
            <span>{CommonUtils.removeProtocol(client?.companyName)}</span>
          </a>
        </div>
      </div>

      <div
        className={`desktop-hidden second-header collapse${menuOpen ? " show" : ""
          }`}
        style={{ height: menuOpen ? 46 * menuItems.length : "" }}
      >
        <div className="second-header__left">
          {menuItems.map((item, index) => (
            <Link
              to={item.href}
              onClick={(e) => onClickLink(e, item.href)}
              key={index}
              className={item.href && item.href === pathname ? "active" : ""}
            >
              {item.icon}
              <span>{item.text}</span>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
}

export default SecondHeader;
