import { Button, DatePicker } from "antd";
import { RangePickerProps } from "antd/lib/date-picker";
import moment, { Moment } from "moment";
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { DATE_TIME_FORMAT } from "../../../constants/app-constants";
import { DateRangeValues } from "../../../constants/app.enums";
import DateUtils from "../../../utils/date.utils";
import SVGIcons from "../../icons/svgs";
import "./index.scss";

const { RangePicker } = DatePicker;

export interface DateRangePickerValues {
  startDate: Moment;
  endDate: Moment;
  value: string;
}

export interface AppDateRangePickerProps {
  defaulValue: string;
  hasBorder?: boolean;
  onChange: (values: DateRangePickerValues) => void;
  disabled?: boolean;
  defaultDateRange?: Moment[] | null
}

function AppDateRangePicker(props: AppDateRangePickerProps) {
  const { defaulValue, hasBorder, onChange: onChangeProps, disabled, defaultDateRange } = props;
  const { t } = useTranslation();
  const [dateRangeOpen, setDateRangeOpen] = useState(false);

  const [rangeSelected, setRangeSelected] = useState(defaulValue);
  const [rangeOldSelected, setRangeOldSelected] = useState(defaulValue);

  const [dateOriginValue, setDateOriginValue] = useState<Moment[]>([
    moment(),
    moment(),
  ]);

  const [dateValue, setDateValue] = useState<Moment[]>([moment(), moment()]);

  const ref = useRef<any>();
  const forceBlurRef = useRef<boolean>(false);

  const items = [
    {
      text: t("common.last7days"),
      key: DateRangeValues.last7days,
    },
    {
      text: t("common.lastMonth"),
      key: DateRangeValues.lastMonth,
    },
    {
      text: t("common.last3Months"),
      key: DateRangeValues.last3Months,
    },
    {
      text: t("common.lastYear"),
      key: DateRangeValues.lastYear,
    },
    {
      text: t("common.customRange"),
      key: DateRangeValues.customRange,
    },
  ];

  useEffect(() => {
    if (defaulValue) {
      const rangeDate = getDateRange(defaulValue);
      onChange(rangeDate[0], rangeDate[1], defaulValue);
      selectRange(defaulValue);
    }
  }, [defaulValue]);

  function getDateRange(value: DateRangeValues | string) {
    let endDate = moment().add(-1, "days");
    let startDate = moment().add(-1, "days");

    switch (value) {
      case DateRangeValues.lastMonth:
        startDate = moment().add(-1, "months");
        break;
      case DateRangeValues.lastMonthSocial:
        startDate = moment().add(-1, "months");
        endDate = moment();
        break;
      case DateRangeValues.lastYear:
        startDate = moment().add(-1, "years");
        break;
      case DateRangeValues.last7days:
        startDate = moment().add(-7, "days");
        break;
      case DateRangeValues.last3Months:
        startDate = moment().add(-3, "months");
        break;
      default:
        break;
    }

    return [startDate, endDate];
  }

  function selectRange(value: DateRangeValues | string) {
    setRangeSelected(value);
    const dateRange = getDateRange(value);
    setDateValue(dateRange);
  }
  
  useEffect(()=> {
    if(defaultDateRange)
      setDateValue(defaultDateRange);
  }, [defaultDateRange])

  function dateRangeChange(e: any) {
    const newArray = [...dateValue];
    if (e[0]) {
      newArray[0] = e[0];
    }

    if (e[1]) {
      newArray[1] = e[1];
    }

    setDateValue(newArray);

    if (e.length >= 2) {
      setRangeSelected(DateRangeValues.customRange);
      setDateValue(newArray);
    }
  }

  const disabledDate: RangePickerProps["disabledDate"] = useCallback(
    (current: Moment) => {
      const outOfRange =
        current <= moment().add(-1, "years").add(-1, "d") || current > moment();
      return outOfRange;
    },
    []
  );

  let blurTimeOut: any = undefined;
  function onFocus() {
    if (blurTimeOut) {
      clearTimeout(blurTimeOut);
    }

    if (forceBlurRef.current) {
      forceBlur();
    } else {
      setDateRangeOpen(true);
    }
  }

  function onBlur() {
    if (forceBlurRef.current) {
      return;
    }

    blurTimeOut = setTimeout(() => {
      cancel();
    }, 100);
  }

  function apply() {
    onChange(dateValue[0], dateValue[1], rangeSelected);
    setDateRangeOpen(false);
    forceBlur();
  }

  function onChange(startDate: Moment, endDate: Moment, value: string) {
    onChangeProps({
      endDate: endDate,
      startDate: startDate,
      value: value,
    });

    setDateOriginValue([startDate, endDate]);
    setRangeOldSelected(value);
  }

  function close() {
    cancel();
    forceBlur();
  }

  function cancel() {
    setDateRangeOpen(false);
    setDateValue(dateOriginValue);
    setRangeSelected(rangeOldSelected);
  }

  function forceBlur() {
    forceBlurRef.current = true;
    setTimeout(() => {
      forceBlurRef.current = false;
    }, 300);
    ref.current.blur();
  }

  const footer = () => {
    return (
      <div className="app-range-picker__footer-wrapper">
        <div className="app-range-picker__footer-wrapper__action-wrapper">
          {items
            .filter((x) => x.key !== DateRangeValues.customRange)
            .map((item) => (
              <Button
                key={item.key}
                type="text"
                className={`${item.key === rangeSelected ? "active" : ""}`}
                onClick={() => selectRange(item.key)}
              >
                {item.text}
              </Button>
            ))}
        </div>
        <Button className="app-range-picker__apply" type="text" onClick={apply}>
          {t("common.apply")}
        </Button>
      </div>
    );
  };

  const panelRender = (originPanel: ReactNode) => {
    return (
      <>
        <div className="app-range-picker__header">
          <div className="app-range-picker__header__value">
            <span>{DateUtils.dateView(dateValue[0])}</span>
            <span className="separator">-</span>
            <span>{DateUtils.dateView(dateValue[1])}</span>
          </div>
          <span className="close-btn" onClick={close}>
            <SVGIcons.CloseIcon />
          </span>
        </div>
        {originPanel}
      </>
    );
  };

  return (
    <div
      className={`app-range-picker mobile-hidden ${
        hasBorder ? "app-range-picker-has-border" : ""
      } ${disabled ? "app-range-picker-disabled" : ""}`}
    >
      <SVGIcons.CalendarIcon />
      <div className="app-range-picker__input-wrapper">
        <RangePicker
          disabledDate={disabledDate}
          format={DATE_TIME_FORMAT.viewFormat}
          suffixIcon={null}
          separator="-"
          open={dateRangeOpen}
          onChange={dateRangeChange}
          value={[dateValue[0], dateValue[1]]}
          allowClear={false}
          onFocus={onFocus}
          onBlur={onBlur}
          renderExtraFooter={footer}
          panelRender={panelRender}
          placeholder={["mm dd, yyyy", "mm dd, yyyy"]}
          ref={ref}
          disabled={disabled}
        />
      </div>
    </div>
  );
}

export default AppDateRangePicker;
